// Project colors
$turquoise: #33aab3;
$gray: #3c3c3a;
$red: #ef4026;
$yellow: #ffb500; //#fdb912;
$darkblue: #1b5298;
$lightcolor: #eef6fa;

$alert-color: $yellow;
$success-color: #43ac6a;
$warning-color: $red;
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #0a0a0a;
$white: #ffffff;

$foundation-palette: (
  primary: $turquoise,
  secondary: $gray,
  tertiary: $turquoise,
  quaternary: $turquoise,
  light: $lightcolor,
  success: $success-color,
  warning: $warning-color,
  alert: $alert-color
);
@include add-foundation-colors;
$tertiary-color: map-get($foundation-palette, tertiary);
$quaternary-color: map-get($foundation-palette, quaternary);

$body-font-color: #555;
$body-background: #fff;
$header-color: $primary-color;
$header-small-font-color: #888;
$sidebar-background: $lightcolor;

$hero-overlay-color: #fff;
$hero-gradient-degree: 72deg;
$pagehader-bg: #fff;
$pagenav-color: $body-font-color;
$pagenav-active-color-1stlevel: $secondary-color;
$pagenav-active-color-2ndlevel: $primary-color;
$pagenav-shadow: #fff;

// buttons
$button-background: $primary-color;
$button-radius: rem(3);
$button-styles: (
  primary: (
    "background": $primary-color,
    "background-hover": scale-color($primary-color, $lightness: -20%),
    "background-hollow-scale": -40%,
    "text-color": #fff
  ),
  secondary: (
    "background": $secondary-color,
    "background-hover": scale-color($secondary-color, $lightness: -8%),
    "background-hollow-scale": -17%,
    "text-color": #fff
  ),
  tertiary: (
    "background": $tertiary-color,
    "background-hover": scale-color($tertiary-color, $lightness: -28%),
    "background-hollow-scale": -35%,
    "text-color": #fff
  ),
  quaternary: (
    "background": $quaternary-color,
    "background-hover": scale-color($quaternary-color, $lightness: -28%),
    "background-hollow-scale": -35%,
    "text-color": #fff
  )
);
